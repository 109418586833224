@import "../../variables"

.responsive_menu
  position: fixed
  display: none
  height: 100%
  width: 75%
  top: 0
  @include putTransition(.3s)
  left: -75%
  background-color: $primaryBlue
  box-shadow: 0 3px 12px rgba(0,0,0,0.2)
  z-index: 999999
  &.open
    left: 0
  ul
    list-style: none
    padding: 0
    li
      opacity: .8
      color: #FFF
      font-size: 25px
      padding: 3% 10%
      &.selected
        opacity: 1
        font-weight: 600
        border-left: 5px solid #FFF

@media (max-width: 641px)
  .responsive_menu
    display: block