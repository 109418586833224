@import "../../../assets/variables"

.configuracoes_body
  margin: 35px 50px
  box-shadow: 0 5px 10px rgba(0,0,0,0.2)
  padding: 45px
  padding-top: 30px
  form > div:first-child
    display: flex
    width: 100%
    alignItems: flex-end
    marginTop: 35
  .more_data
    display: flex
    width: 100%
    marginTop: 35
  .foto_changer
    width: 100px
    height: 100px
    border-radius: 300px
    background-size: cover
    background-clip: border-box
    background-repeat: no-repeat
    margin-right: 20px
    cursor: pointer
    &:hover
      div
        transform: scale(1)
    div
      position: relative
      left: 10px
      color: #FFF
      background-color: rgba(0,0,0,0.6)
      top: 42.5px
      width: 60px
      text-align: center
      font-size: 11px
      padding: 5px 10px
      border-radius: 3px
      @include putTransition(.15s)
      transform: scale(0)


@media (max-width: 641px)
  .horarios_text
    display: none
  .configuracoes_container
    .configuracoes_body
      margin: 35px 10px
      padding: 10px 10px 10px 15px
      .more_data
        margin-top: 10px
        flex-direction: column
      form > div:first-child
        justify-content: center
        flex-direction: column
    .foto_changer
      align-self: center
