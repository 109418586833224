@import "../../variables"

.container_reserva_cliente
  display: flex
  background: transparent linear-gradient(269deg,#1febf1,#0eb6bb) 0 0 no-repeat padding-box
  padding: 1% 2%
  color: #FFF
  @include putTransition()
  margin-bottom: 1%
  border-radius: 10px
  box-shadow: 0 2px 5px rgba(0,0,0,0.2)
  &.executado.pago
    background: transparent linear-gradient(270deg, #F8F8F8 0%, #EFEFEF 100%) 0 0 no-repeat padding-box
    color: $darkGrey
  &.executado
    background: transparent linear-gradient(270deg, #FF4444, #880000 100%) 0 0 no-repeat padding-box
    color: #FFF
  div
    flex-grow: 1
  h1,h2,h3,h4
    margin: auto 0
    font-weight: 500

@media (max-width: 641px)
  .container_reservas
    .flex
      flex-direction: row
  .reservas_page_container
    .flex
      .cancel_checkbox_container
        width: auto !important
        .cancel_checkbox
          width: 28px !important
          height: 28px !important
  .container_reserva_cliente
    padding: 10px 10px
    width: 100%
    h1
      font-size: 1.2em
    h4
      font-size: .8em
    h2
      font-size: 1em
    & > div:last-child
      display: none
