@import "../../variables"

.card_profissional
  margin: 1%
  box-shadow: 0 4px 8px rgba(0,0,0,0.2)
  padding: 1%
  border-radius: 7px
  display: flex
  flex-direction: column
  text-align: center
  justify-content: center
  width: 16%
  h2, h3
    margin: 0
    font-weight: 500
  h3
    font-weight: 400
    color: $grey
    margin-bottom: 5%
  img
    margin: auto
    border-radius: 500px
    width: 75px
    object-fit: cover
    height: 75px
  button
    align-self: flex-end
