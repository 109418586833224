@import "./src/assets/variables"

.container_reservas
  margin-left: 2.5%
  width: 90%
  padding: 1% 2%
  margin-bottom: 25px
  background-color: #FFFFFF
  padding-bottom: 2%
  border-radius: 10px
  box-shadow: 0px 5px 9px rgba(0,0,0,0.3)
  .title
    color: $darkGrey
    font-weight: 500
  .empty_array
    color: $grey
    font-weight: 400
  .flex
    width: 100%
    h1
      width: 100%
      align-self: flex-start
  .cancelar_agendamentos
    justify-self: flex-end
    margin: 20px 0
    width: 45%

@media (max-width: 641px)
  .container_reservas
    .title
      text-align: center
    .flex
      &:first-child
        flex-direction: column
      h1
        margin-bottom: 5px
        flex-direction: column
      .button
        width: 100%
  .reservas_page_container
    .cancelar_button
      margin-top: 10px
      padding: 10px