@import "../../variables"

.snack_container
  background: transparent linear-gradient(269deg, #1FEBF1 0%, #0EB6BB 100%) 0% 0% no-repeat padding-box
  color: #FFF
  padding: 0 3%
  border-radius: 500px
  width: 25%
  box-shadow: 0 5px 7px rgba(0,0,0,0.1)
  h2
    margin: 13px auto
    text-align: center
    font-size: 18px
    font-weight: 400