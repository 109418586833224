@import "../../../assets/variables"

.container_adms
  display: flex
  margin-top: 2%
  margin-left: 2%
  width: 92%

@media (max-width: 655px)
  .container_adms
    flex-wrap: wrap
    justify-content: center
    .header_salas
      display: block
      margin-bottom: 20px
    .card_administrativo
      width: 80%
      padding: 20px
      margin-bottom: 5%