.proximos_agendamentos
  .week_calendar
    display: flex
    .day
      flex: 1
      text-align: center
      border-right: 1px solid #D0D0D0
      h3, h5
        margin: 0
  .agendamento_box
    padding: 15px 10px
    margin: 8px 5px
    border-radius: 5px
    cursor: pointer
    transition: all .3s ease-in-out
    box-shadow: 0 2px 4px rgba(0,0,0,0.2)
    background: transparent linear-gradient(180deg, #98EEF0 0%, #02BAC0 100%) 0% 0% no-repeat padding-box
    &:hover
      box-shadow: 0 2px 10px rgba(0,0,0,0.2)

    p
      color: #FFF
      margin: 0
      &:first-child
        font-weight: 600

@media (max-width: 700px)
  .proximos_agendamentos
    .week_calendar
      flex-direction: column
      text-align: left
      .day
        border: none
        .agendamentos
          display: flex
          flex-wrap: wrap
          justify-content: center
          & .agendamento_box
            width: 80px
