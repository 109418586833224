@import "../../../assets/variables"

.pages_paginator
  display: flex
  width: 100%
  margin: auto
  justify-content: center
  align-items: center
  div
    width: 25px
    margin: 25px 0
    align-self: flex-end
  .page
    color: #888
    cursor: pointer
    @include putTransition(.2s)
    &.selected
      font-size: 20px
      color: $primaryBlue
      font-weight: 700
