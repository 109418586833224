.profissional_snack
  display: flex
  margin-top: 10px
  background-color: #F0F0F0
  border-radius: 100px
  margin-right: 10px
  p
    font-size: 10.5px
    margin: auto 10px
  img
    width: 20px
    height: 20px
    border-radius: 50px
    object-fit: cover