.modo_paisagem
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 9999
  display: none
  justify-content: center
  height: 100%
  background-color: rgba(0,0,0,0.75)
  h2
    margin: auto
    color: #FFF

//@media (max-height: 360px)
//  .modo_paisagem
//    display: flex
