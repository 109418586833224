.horario_atendimento
  display: flex
  .column
    flex: 1
    .plus
      height: 50px
  i
    cursor: pointer
    margin: auto 15px
    padding-bottom: 10px
    color: #D9D9D9
    &:hover
      color: #999

@media (max-width: 641px)
  .full_container_horarios
    display: none
  .horario_atendimento
    display: none
    flex-direction: column
    align-items: center
    justify-content: center
    .column > div
      display: flex
      flex-direction: column
