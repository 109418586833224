@import "../../variables"

.card_administrativo
  margin: 1%
  box-shadow: 0 4px 8px rgba(0,0,0,0.2)
  padding: 1%
  border-radius: 7px
  display: flex
  flex-direction: column
  text-align: center
  justify-content: center
  width: 16%
  h2, h3
    margin: 0
    font-weight: 500
  h2
    margin-bottom: 5%
  img
    border-radius: 500px
    width: 75px
    height: 75px
    object-fit: cover
    margin: auto
