@import "../../../../variables"

.tipos_usuarios
  div
    padding: 10px 15px
    border-radius: 5px
    margin-bottom: 2%
    cursor: pointer
    @include putTransition(.2s)
    box-shadow: 0 0px 4px rgba(0,0,0,0.2)
    &:hover
      box-shadow: 0 0px 13px rgba(0,0,0,0.2)
    &:last-child
      margin-bottom: 3%
    h1
      font-size: 16px
