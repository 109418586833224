$primaryBlue: #1BDFE4
$darkerBlue: #1BC2C8
$lighterBlue: #4BE7EB

$breakpoints: (phone: 640px, tablet: 768px, desktop: 1024px) !default
$grey: #B7B7B7
$darkGrey: #707070
$lightGrey: #F9F9F9

@mixin putTransition($transition: .2s)
  transition: all $transition ease-in-out

@mixin fullWidth()
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%