@import "../../../../../assets/variables"
    
.birthday_card
    position: absolute
    top: 15%
    padding: 45px 25px
    left: 25%
    background-color: #FFF
    z-index: 50000
    border-radius: 5px
    box-shadow: 0 6px 12px rgba(0,0,0,0.12)
    width: calc(50% - 25px * 2)
    height: auto
    &.hidden
        display: none
    img
        width: 50%
    text-align: center
    button
        background-color: $primaryBlue
        font-size: 1.2em
        padding: 14px 30px
        border: none
        box-shadow: 0 5px 12px rgba(0,0,0,0.2)
        cursor: pointer
        border-radius: 5px
        color: #FFF
        font-weight: 700
        letter-spacing: 1.9px
        text-transform: uppercase

@media (max-width: 641px)
    .birthday_card
        left: 5%
        width: calc(80% - 8px * 2)