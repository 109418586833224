@import "../../../assets/variables"

.salas_container
  margin-left: 5%
  width: 90%
  .header_salas
    display: flex
    div
      width: 100%
      &:last-child
        display: flex
        justify-content: flex-end
        button
          margin: auto
  .salas
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center

@media (max-width: 655px)
  .salas_container
    .header_salas
      display: block
      margin-bottom: 20px
    .salas
      flex-wrap: wrap
      justify-content: center
      .card_sala
        width: 80%
        padding: 20px
        margin-bottom: 5%
