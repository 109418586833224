@import "../../variables"

.topbar_container
  img
    object-fit: cover
  .tabs
    flex-grow: 1
    justify-content: center
    .tab
      display: flex
      padding: 0 3%
      color: $grey
      cursor: pointer
      @include putTransition()
      border-bottom: 2px solid transparent
      p
        margin: auto 0
      &.selected
        color: $primaryBlue
        border-bottom: 2px solid $primaryBlue
      &:hover:not(.selected)
        border-bottom: 2px solid $grey
.topbar_container_adm
  display: flex
  .responsive_menu
    display: none
  .sidebar_adm
    position: fixed
    top: 0
    left: -350px
    height: 100%
    padding-top: 80px
    width: 350px
    background-color: #FFFFFF
    display: block
    z-index: 1000
    box-shadow: 2px 3px 6px rgba(0,0,0,0.1)
    @include putTransition(.3s)
    .tab
      padding: 1px 20px
      cursor: pointer
      color: #888
      @include putTransition(.2s)
      &:hover
        color: #BFBFBF
      &.selected
        border-left: 5px solid $primaryBlue
        color: $primaryBlue
        font-weight: 500
    &.open
      left: 0
  .img_container_resp
    display: none
  .img_container
    background-color: transparent
    .hamb
      cursor: pointer
      margin: auto
      z-index: 1001
      &.open
        div:first-child
          transform: rotate(45deg)
        div:nth-child(2)
          opacity: 0
        div:last-child
          width: 40px
          margin-top: -15px
          transform: rotate(-45deg)
      &:hover
        div
          box-shadow: inset 40px 0px 0px darken($primaryBlue, 10)
      div
        width: 40px
        height: 4px
        border-radius: 5px
        @include putTransition(.2s)
        background-color: $primaryBlue
        &:not(:last-child)
          margin-bottom: 3px
        &:nth-child(2)
          @include putTransition(.3s)
          width: 35px
        &:last-child
          @include putTransition(.4s)
          width: 27px
    img
      width: 24px
      margin: auto 0

@media (max-width: 641px)
  .topbar_container_adm
    margin-bottom: 20px
    background-color: $primaryBlue
    .sidebar_adm
      display: none
    .responsive_menu
      display: block
    .img_container
      display: none
    .img_container_resp
      margin: auto
      display: flex
      height: 75px
      img
        margin: auto
        width: 150px
    .tabs
      display: inline-block
      visibility: hidden
      position: absolute
      margin-top: 100px
      left: 230px
    .user_data
      font-size: 7.5pt
    .log-off
      position: absolute
      top: 13px
      right: 30px
      i
        color: #FFF
  .profissionais_container
    display: inline-block
    margin-left: 10%
