@import "../../../../variables"

$modalWidth: 45%

header
  margin: 2% 0 4% 0
  display: flex
  h1
    color: $darkGrey
    margin: 0
  h3
    color: $grey
    margin: 0

.horas_intervalo
  display: flex
  justify-content: center
  div
    margin: 0 3%
  h2
    color: $darkGrey
.options
  display: flex
  justify-content: center

  .option
    border: 1px solid $primaryBlue
    width: 20%
    @include putTransition()
    cursor: pointer
    text-align: center

    p
      color: $primaryBlue
      font-weight: 500

    &:first-child
      border-radius: 100px 0 0 100px

    &:last-child
      border-radius: 0 100px 100px 0

    &:hover
      background-color: $lightGrey

    &.selected
      background-color: $primaryBlue

      p
        color: #FFF

.resume_container
  display: flex
  margin: 5% 0
  justify-content: center

  h2, h3
    margin: 0

  h2
    color: $darkGrey

  h3
    color: $grey

  div
    margin: 0 3%

    &:first-child
      text-align: right

.footer
  display: flex
  justify-content: flex-end
  float: right
  width: 60%
  margin-bottom: 2%
  button
    width: 50%
  button:not(:last-child)
    margin-right: 15px


@media (max-width: 641px)
  .options
    width: 90%
    margin-left: 5%
    .option
      width: 100%

  .footer
    margin: 5% 3%
    width: 50%
    button
      width: 100%
