@import "../../../../variables"

.modal_new_profissional
  button
    margin-bottom: 20px
.crud_ops
  margin-right: 15px
  width: 100%
  button
    background: none
    color: $grey
    box-shadow: none
    &:hover
      color: $darkGrey
      box-shadow: 0 0px 3px rgba(0,0,0,0.1)