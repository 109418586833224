@import "../../../variables"

.menu_container
  position: absolute
  top: 75px
  background-color: #FFF
  box-shadow: 0 3px 6px rgba(0,0,0,0.2)
  right: 20px
  opacity: 0
  @include putTransition()
  visibility: hidden
  ul
    list-style: none
    padding-inline-start: 0
    margin: 0
    li
      cursor: pointer
      padding:  20px 55px 20px 25px
      @include putTransition(.2s)
      &:hover
        background-color: rgba(0,0,0,0.04)
  &.open
    visibility: visible
    opacity: 1
