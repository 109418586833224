.bloquear_salas_body
  margin-bottom: 35px
  .select_salas__menu
    max-height: 130px
    div
      max-height: 130px
      overflow-x: hidden
  .flex_content
    flex-wrap: wrap
    display: flex
    align-items: center
    div:first-child
      flex-grow: 1
    input[type='date']
      padding: 8px 10px
      border-radius: 5px
      font-family: 'Arial'
      border: 1px solid #B5B5B5
      width: 230px
      margin-right: 50px
    .select_container
      width: 130px
      &:first-child
        margin-right: 15px
      &:last-child
        margin-left: 15px
      .select__menu
        max-height: 90px
        div
          max-height: 90px
          overflow-x: hidden
