@import "../../../../assets/variables"
    
.warnings_container
    margin-left: 5em
    p
        margin: 0
        &:first-child
            font-size: 1.3em
    .warning
        margin: 15px 0
    .date
        color: #999999
        cursor: pointer
        @include putTransition
        &:hover
            color: #333333
    .pages
        display: flex
        div
            margin: 10px 5px
            color: #aaa
            @include putTransition()
            cursor: pointer
            &.active
                font-weight: 600
                line-height: 20px
                font-size: 1.2em
                color: $primaryBlue
            &:hover
                color: #333
            &:first-child
                margin: 10px 5px 10px 0px
