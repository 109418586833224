@import '../../assets/variables.sass'

.login_container
  background-color: $primaryBlue
  @include fullWidth
  overflow: hidden
  .logo_container
    position: absolute
    top: 35px
    z-index: 1001
    display: flex
    p
      font-weight: 700
      color: #FFFFFF
      font-size: 35px
      position: absolute
      bottom: -50px
      text-shadow: 0 1.5px 3px rgba(0,0,0,0.3)
      text-transform: uppercase
      margin-left: 15px
  .ball
    position: absolute
    width: 950px
    border-radius: 5000px
    height: 950px
    bottom: -300px
    right: -120px
    background-color: $lighterBlue
  .login
    position: absolute
    right: 100px
    width: 450px
    bottom: 45px
    h1
      color: #FFF
      font-size: 50px
      text-shadow: 1px 1px 3px rgba(0,0,0,0.3)
      text-align: center
    .card
      background-color: #FFF
      box-shadow: 0 3px 15px rgba(0,0,0,0.3)
      border-radius: 10px
      padding: 30px
      @include putTransition(.2s)
    .forgot_pwd
      color: darken($darkerBlue, 15)
      cursor: pointer
      &:hover
        color: darken($darkerBlue, 35)

// para telas pequenas
@media(max-width: 1380px)
 .login_container
  .logo_container
    img
      width: 75%
    p
      font-size: 25px



//Para Tablets
@media (min-width: 640px) and (max-width: 1025px)
  .login_container
    .logo_container
      img
        width: 80%
      p
        font-size: 2.7vw
        margin-left: 0px

//Para celulares
@media (max-width: 641px)
  .login_container
    .forgot_pwd
      display: block
      text-align: center
    .logo_container
      justify-content: center
      img
        margin-top: 50px
    .ball
      left: -200px
      margin: auto
      width: 750px
      height: 750px
    img
      margin-top: 5%
      width: 90%
    p
      display: none
    .login
      width: 90%
      bottom: 5%
      z-index: 1001
      right: 5%


