.mobile_agendamentos
  display: none
  margin: 0 10px
  margin-bottom: 30px
  .week
    text-align: center
    span
      color: #999
      &:first-child
        i
          float: left
      &:last-child
        float: right
  .reservas_container
    display: flex
    flex-wrap: wrap
    .add
      width: 98px
      margin-left: 4px
      height: 62px
      display: flex
      justify-content: center
      align-items: center
      background-color: #F0F0F0
      color: #777
      font-size: 3vw
      border-radius: 5px
      box-shadow: 0 3px 5px rgba(0,0,0,0.1)
  .reserva
      background: transparent linear-gradient(180deg, #98EEF0 0%, #02BAC0 100%) 0% 0% no-repeat padding-box
      color: #FFFFFF
      width: 75px
      justify-content: center
      flex-direction: column
      height: 32px
      border-radius: 5px
      margin: 1.5px
      padding: 15px
      box-shadow: 0 3px 7px rgba(0,0,0,0.2)
      h2,h3, h4
        margin: 0
        text-align: center
        font-size: 3.25vw
        font-weight: 400
        color: #FFF
@media (max-width: 641px)
  .mobile_agendamentos
    display: block
    .date_side
      display: block
      margin-top: 3px
      color: #888
      font-weight: 400
      font-size: 16px