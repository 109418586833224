.disponibilidade_container
  margin-left: 5%
  width: 90%
  .sala_disponivel
    font-weight: 700
    border-bottom: 1px solid #00000022
    margin: 0
    padding: 10px 3px
    &:nth-of-type(1)
      margin-top: 20px
    span
      font-weight: 400
      font-style: italic
      opacity: 0.4
  .options_horas
    display: flex
    align-items: center
    .option
      width: 230px
      &:first-child
        margin-right: 15px
      &:last-child
        margin-left: 15px


@media(max-width: 1023px)
  .disponibilidade_container
    margin-top: 30px
    margin-bottom: 40px
    .campos
      & > div
        display: flex
        p
          margin-right: 10px
        & > div
          width: 100%
          .option
            width: 100%
    input[type="date"]
      flex: 1
      outline: 0
      min-height: 38px
      border: 1px solid hsl(0,0%,80%)
      widtH: calc(100% - 16px)
      border-radius: 4px
      padding: 2px 8px
    .button
      margin-top: 10px

@media (min-width: 1024px)
  .disponibilidade_container
    width: 80%
    .sala_option
      width: 250px
    .campos
      display: flex
      align-items: center
      justify-content: space-between
      .button
        position: relative
        top: 22px
        padding: 10px 30px
    input[type="date"]
      padding: 8px
      border-radius: 4px
      border: 1px solid hsl(0, 0, 80%)
