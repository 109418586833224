.sala_bloqueada
  padding: 15px
  border-radius: 8px
  box-shadow: 0 3px 10px rgba(0,0,0,0.2)
  display: flex
  margin-bottom: 15px
  align-items: center
  .data
    width: 100%
    h2, p
      margin: 0
  .btn_container
    width: 30%