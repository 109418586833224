@import '../../variables'

.reservas_page_container
  .flex
    @include putTransition()
    margin-bottom: 0px
    &.flex_margin
      margin-bottom: 1%
    .cancel_checkbox_container
      margin: auto
      cursor: pointer
      @include putTransition()
      width: 50px
      .cancel_checkbox
        width: 35px
        border-radius: 500px
        border: 1px solid lighten($grey, 10)
        height: 35px
        display: flex
        @include putTransition(.1s)
        i
          margin: auto
          transform: scale(0)
          @include putTransition(.1s)
        &.checked
          border: 1px solid $primaryBlue
          i
            transform: scale(1)
            color: $primaryBlue
  .cancelar_button
    padding: 1.5% 0
    background-color: #f53443
    &.loading
      background-color: $grey
