@import "../../../../assets/variables"

.profissional_list_obj
    box-shadow: 0 3px 5px rgba(0,0,0,.2)
    border-radius: 10px
    td:first-child.not_indicate
      border-radius: 5px
      border-left: 5px solid #F00
    .inside
      margin-right: 35px
    img
      width: 50px
      height: 50px
      object-fit: cover
      border-radius: 50px
    td
      color: #555
      padding: 10px
      &:last-child
        padding-right: 30px
    .cpf
      font-size: 13px
    .cep
      font-size: 12px
      color: #888
    .creditos_container
      p
        font-size: 14px
      *
        margin: 0
      p:last-child
        @include putTransition()
        color: #999
        cursor: pointer
        &:hover
          color: #333
