@import "../../../../variables"

.footer_edit_agendamento
  .crud_ops
    .button.submit
      background-color: $primaryBlue
      color: #FFF
      box-shadow: 0 2px 3px rgba(0,0,0,0.2)
.body_edit_agendamento
  .counter
    display: flex
    margin: 0 auto
    margin-bottom: 20px
    h1, h2
      margin: auto 10px
      i
       color: #999
       cursor: pointer
       @include putTransition()
       &:hover
          color: #333
  h2
    color: $darkGrey
  h3
    font-weight: 400
    color: $grey