@import "./src/assets/variables"

.checkbox_container
  display: flex
  align-items: center
  .checkbox
    position: relative
    border: 2px solid $grey
    width: 15px
    flex-grow: 0
    cursor: pointer
    margin: auto 0
    border-radius: .3em
    height: 15px
    margin-right: 9px
    @include putTransition()
    &.checked
      border-color: $darkerBlue
      box-shadow: inset 15px 0 0 0 $primaryBlue
  label
    color: darken($grey, 30)
