.btn_plus_horario
    background: transparent
    border: none
    font-size: 1em
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 5px
    border: 1px solid #FFF
    transition: all .2s ease-in-out
    &:hover
        border: 1px solid #333
        i
            color: #333
    i
        padding: 10px
        margin: 0
        color: #333