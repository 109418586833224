
@import "../../../assets/variables"

.financeiro_page
    .export
        float: right
        font-size: .6em
        a
            color: #999
            text-decoration: none
            &:hover
                color: #555
    .pages
        display: flex
        margin: auto
        justify-content: center
        div
            width: 25px
            margin: 25px 0
            align-self: flex-end
        .page
            color: #888
            cursor: pointer
            @include putTransition(.2s)
            &.selected
                font-size: 20px
                color: $primaryBlue
                font-weight: 700
    .finances
        flex: 1
        margin: auto 15px
        height: 80px
    .container_adms
        display: block
    .titulos
        margin-left: 40px
        flex: 1
        .titulo
            margin-right: 40px
            display: flex
            margin-bottom: 8px
            h2
                font-weight: 500
                font-size: 1em
                width: 50%
                margin: 0 20px 0 0 
            p
                width: 20%
                color: #777
                margin: 0
            button
                margin-right: 10px
                text-transform: uppercase
                background-color: transparent
                border: none
                cursor: pointer
                @include putTransition
                letter-spacing: .8px
                font-weight: 600
                color: #BBB
            .edit
                &:hover
                    color: #ffcc00
            .remove
                &:hover
                    color: #FF3300
    .indicators
        display: flex
        width: 100%
        .indicator_card
            flex: 1
            color: #FFF
            margin: 0 10px
            padding: 0 25px
            border-radius: 7px
            background-color: $primaryBlue
            box-shadow: 0 3px 10px rgba(0,0,0,0.2)
            &:first-child
                margin-left: 0
            &:nth-child(2)
                background-color: darken($primaryBlue, 5)
            &:nth-child(3)
                background-color: darken($primaryBlue, 10)
            h1
                margin-top: 0
            h2
                color: #FFF
                margin-bottom: 0
                font-weight: 400
    h2
            color: #777
            font-weight: 400
    .header
        margin-left: 128px
        margin-bottom: 8px
        text-align: center
        display: flex
        div
            color: #777
            flex: 1
            text-align: center
            &:first-child
                margin-left: 15px
            &:nth-child(2)
                flex: 0.5
            &:nth-child(4)
                flex: 0.5
            &:nth-child(5)
                flex: 0.5
            &:nth-child(6)
                flex: 0.5
            &:nth-child(7)
                flex: 0.3
            &:nth-child(8)
                flex: 0.3
            &:nth-child(9)
                width: 30px
    .row
        display: flex
        width: 100%
        .date_written
            p
                text-align: center
        .date
            position: relative
            width: 12px
            height: 12px
            background-color: $primaryBlue
            margin: auto 35px auto 10px
            border-radius: 50px
            .bar
                width: 2px
                height: 80px
                background-color: $primaryBlue
                margin: auto
        &:last-child
            .date
                .bar
                    height: 0
        .row_container
            width: 100%
            display: flex
            margin: 5px auto
            border-radius: 5px
            box-shadow: 0 0px 8px rgba(0,0,0,0.15)
            .type
                display: flex
                width: 8px
                background-color: #3C3
                border-radius: 5px 0 0 5px
                &.saida
                    background-color: #F33
                &.neutral
                    background-color: #DA0
            p
                text-align: center
                flex: 1
                margin: auto 0
                padding: 18px 0
                &:nth-child(5)
                    flex: 0.5
                &:nth-child(6)
                    flex: 0.3
                &:nth-child(7)
                    flex: 0.4
                &:nth-child(8)
                    flex: 0.4
                &:nth-child(9)
                    flex: 0.2
                    i
                        color: #CCC
                        cursor: pointer
                        transition: all .2s ease
                        &:hover
                            color: #AAA
