@import '../../variables'
$marginBetween: 5px
$height: 3px
$top: 28px
$left: 25px

.hamburger_container
  position: absolute
  width: 64px
  display: none
  height: 64px
  top: $top
  left: $left
  div
    position: absolute
    height: $height
    width: 35px
    background-color: #FFF
    border-radius: 9px
    &:nth-child(1)
      margin-top: $marginBetween + $height
    &:nth-child(2)
      margin-top: ($marginBetween + $height) * 2

@media (max-width: 641px)
  .hamburger_container
    display: flex
    top: $top
