@import "../../../../variables"

.modal_sala_body
  .carrousel
    display: flex
    justify-content: center
    img
      width: 90%
    i
      margin: auto 5%
      cursor: pointer
  .sala_details
    display: flex
    div
      width: 100%
      margin-top: 4%
      margin-bottom: 4%
      &:first-child
        width: 50%
      h2, h3
        font-weight: 400
        margin: 0
      h2
        color: $darkGrey
      h3
        color: $grey

@media (max-width: 641px)
  .modal_sala_body
    .sala_details
      flex-direction: column-reverse
      align-items: center
      text-align: center