.prof_pendencias
  width: 90%
  margin-left: 5%
  .pendencia
    margin-left: 30px
  .button_pagar
    background: transparent !important
    box-shadow: none !important
    color: #999999
    padding: 0
    &:hover
      color: #1BC2C8
