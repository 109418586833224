@import "../../../assets/variables"

.reset_password_container
  background-color: $primaryBlue
  position: absolute
  width: 100%
  display: flex
  flex-direction: column
  height: 100%
  p
    color: #FFF
  form
    background-color: #FFF
    margin: auto
    padding: 35px
    border-radius: 5px
    box-shadow: 0 5px 8px rgba(0,0,0,0.2)
    width: 35%
  img
    width: 35%
    margin: auto

@media(max-width: 641px)
  .reset_password_container
    form
      width: 80%
      padding: 20px
    img
      width: 75%
