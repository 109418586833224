@import "../../../../assets/variables"

.fab
    position: fixed
    bottom: 32px
    right: 32px
    border-radius: 32px
    box-shadow: 0 3px 6px rgba(0,0,0,0.2)
    cursor: pointer
    width: 64px
    height: 64px
    background-color: $primaryBlue
    display: flex
    @include putTransition(.2s)
    &.open
        i
            transform: rotate(135deg)
    &:hover
        box-shadow: 0 6px 12px rgba(0,0,0,0.3)
    i
        @include putTransition(.2s)
        margin: auto
        color: #FFF

.fab-entrada, .fab-saida, .fab-lista
    position: fixed
    bottom: 232px
    right: 44px
    border-radius: 32px
    box-shadow: 0 3px 6px rgba(0,0,0,0.2)
    width: 42px
    height: 42px
    display: flex
    @include putTransition
    background-color: #33EF33
    color: #FFF
    opacity: 1
    cursor: pointer
    i
        margin: auto
    &.hidden
        transform: translateY(100px)
        visibility: hidden
        opacity: 0
    &:hover
       & > .hint
           opacity: 1
           visibility: visible
.fab-saida
    background-color: #CC3344
    bottom: 172px
    &.hidden
        transform: translateY(60px)
.fab-lista
    bottom: 114px
    background-color: #ffcc00
    &.hidden
        transform: translateY(20px)
.hint
    margin-top: 5px
    text-transform: uppercase
    letter-spacing: 1.5px
    font-weight: 500
    font-size: 12px
    @include putTransition
    border-radius: 4px
    padding: 5px 10px
    background-color: #777
    z-index: 1001
    opacity: 0
    visibility: hidden
    position: fixed
    right: 44px + 42px + 20px