@import "../../variables"

.alternating_tabs
  display: flex
  margin-left: 5%
  margin: 3% 5%
  width: 60%
  background: #FFFFFF
  border-radius: 500px
  box-shadow: 0 5px 7px rgba(0,0,0,0.1)
  .beta_tag
    position: absolute
    top: -15px
    background: linear-gradient(15deg, #9001ff, #ffb302)
    padding: 5px 12px
    display: inline-block
    width: 60px
    text-align: center
    border-radius: 99px
    font-size: 12px
    font-weight: 500
    right: 10px
  div
    width: 100%
    display: flex
    cursor: pointer
    align-items: center
    position: relative
    @include putTransition(.4s)
    &.selected
      background: transparent linear-gradient(269deg, #1FEBF1 0%, #0EB6BB 100%) 0% 0% no-repeat padding-box
      color: #FFFFFF
      border-radius: 300px
    h2
      text-align: center
      width: 100%
      font-size: 16px
      font-weight: 400

@media (max-width: 641px)
  .alternating_tabs
    margin: 13px auto
    width: 100%
