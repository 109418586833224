@import "../../variables"

.card_sala
  margin: 1%
  box-shadow: 0 4px 8px rgba(0,0,0,0.2)
  border-radius: 7px
  text-align: center
  width: 16%
  img
    object-fit: cover
    width: 100%
    height: 250px
    border-radius: 10px 10px 0 0
  div
    padding: 5%
    h2, h3
      margin-top: 5%
      margin-bottom: 5%
      font-weight: 500
