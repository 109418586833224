@import "src/assets/variables"

.calendar_agendamentos_container
  .chevron_date
    color: $grey
    cursor: pointer
    @include putTransition()
    &:hover
      color: $darkGrey
  h1
    text-align: center
    margin: auto
    padding: 2% 3%
  .container_table
    width: 100%
  .calendar_table
    min-width: 90%
    table-layout: fixed
    border-spacing: 0
    margin-left: 1%
    text-align: center
    padding-bottom: 5%
    thead
      th
        position: sticky
        top: 0px
        z-index: 24
        border-spacing: 0
        background-color: #FFF
        padding: 1.5% 0
    tbody
      tr
        td
          padding: 2% 0
          min-width: 102px
          transform: scale(.96)
        td:not(:first-child)
          cursor: pointer
          @include putTransition()
          &:hover:not(.alugado)
            box-shadow: 0 3px 4px rgba(0,0,0,0.2)
        .alugado
          box-shadow: transparent
          &:hover
            box-shadow: transparent
          i
            color: $grey
      td:first-child
        color: $grey
        width: 7.5%
        z-index: 24
        position: sticky
        left: 0
        background-color: #FFF
    .free
      color: $grey
      box-shadow: 0 1px 2px rgba(0,0,0,0.2)
      background-color: $lightGrey
      margin: 2.5px
      &:hover
        background-color: darken($lightGrey, 1)
      &:active
        transform: translateY(2px)
        box-shadow: 0 .3px 0 rgba(0,0,0,0.1)
    .occupied
      color: #FFFFFF
      box-shadow: 0 1px 2px rgba(0,0,0,0.2)
      border-radius: 5px
      background: transparent linear-gradient(180deg, darken(#98EEF0, 5) 0%, #02BAC0 100%) 0% 0% no-repeat padding-box
      &.cinza
        background: transparent linear-gradient(180deg, darken(#DFDFDF, 15) 0%, #DDD 100%) 0% 0% no-repeat padding-box
      &.vermelho
        background: transparent linear-gradient(180deg, darken(#FF6666, 27) 0%, #FF3333 100%) 0% 0% no-repeat padding-box
      &.verde
        background: transparent linear-gradient(180deg, darken(#66FF66, 35) 0%, #55FF55 100%) 0% 0% no-repeat padding-box
      &.amarelo
        background: transparent linear-gradient(180deg, darken(#FFFF66, 20) 0%, #CCCC00 100%) 0% 0% no-repeat padding-box


@media (max-width: 641px)
  .calendar_agendamentos_container
    h1
      font-size: 20px
      padding: 25px 15px
    .container_table
      .calendar_table
        width: 1000px
