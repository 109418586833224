@import "../../../assets/variables"

.logs
  margin-left: 5%
  width: 90%
  display: flex
  flex-direction: column
  margin-top: 2%
  .pages
    display: flex
    margin: auto
    div
      width: 25px
      margin: 25px 0
      align-self: flex-end
    .page
      color: #888
      cursor: pointer
      @include putTransition(.2s)
      &.selected
        font-size: 20px
        color: $primaryBlue
        font-weight: 700


@media (max-width: 655px)
  .logs
    .card_log
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2)
      padding: 5% 2%
      margin: 4% 0
      display: flex
      border-radius: 10px
      h1
        font-size: 12pt
      img
        width: 40px
        height: 40px