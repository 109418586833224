.dashboard_content
  margin-left: 2.5%
  width: 90%
  display: flex
  margin-bottom: 50px
  & > div
    flex: 1
  .right
    margin-left: 40px

@media (max-width: 700px)
  .dashboard_content
    flex-direction: column
    .right
      margin-left: 10px
      text-align: center
      & > div > div
        padding: 30px 0
        border-bottom: 1px solid #CCC
      table
        font-size: 11px
