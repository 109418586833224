@import "../../../../../assets/variables"

.notification_details
  overflow: hidden
  height: 0
  margin: 0px 45px
  @include putTransition()
  transform: scaleY(0)
  &.open
    height: 150px
    transform: scaleY(1)
  .vistopor_container
    display: flex
    flex-wrap: wrap
.notification
  margin: 8px 30px
  display: flex
  box-shadow: 0 3px 6px rgba(0,0,0,0.2)
  padding: 8px 15px
  border-radius: 5px
  h1, h3, p
    margin: 5px
  .right_side
    margin: auto
    width: 65%
    .texto
      max-width: 50vw
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
  .left_side
    margin: auto
    width: 35%
  .indicator
    margin: auto 18px
    cursor: pointer
    @include putTransition()
    &.opened
      transform: rotate(180deg)
    i
      font-size:  20px
      color: #999