.transactions
  width: 100%
  table
    width: 100%
    text-align: center
    th
      padding-bottom: 20px
    td
      padding: 10px 0
    tr:nth-child(even)
      td
        background-color: #F0F0F0
