.pgmtos
    margin-bottom: 30px
    .btn_secondary
        background: none
        border: none
        cursor: pointer
        color: #BBB
        text-transform: uppercase
        font-weight: 600
        font-size: 16px
        transition: all .2s ease-in-out
        padding-bottom: 3px
        letter-spacing: 1.2px
        &:hover
            color: #999
    .flex
        display: flex
        color: #777
        margin: 20px 0
        p  
            margin: 0
            flex: 1
            &:last-child
                flex: 0.6