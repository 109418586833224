@mixin borderLeft ($color)
  border-left: 8px solid $color

.leads_container
  h2
    margin: 0
  & a
    display: inline-block
    margin-top: 10px
    margin-bottom: 30px

.lead
  width: 95%
  display: flex
  border-radius: 10px
  padding: 10px 20px
  background-color: #FFF
  box-shadow: 0px 0px 10px rgba(0,0,0,0.12)
  align-items: center
  margin-top: 10px
  color: #666
  &.pendente
    @include borderLeft(red)
  &.Ematendimento
    @include borderLeft(yellow)
  &.convertido, &.abandonado, &.finalizado
    @include borderLeft(green)
  & > div
    flex: 1
    p, h2
      margin: 0
  i
    cursor: pointer
    color: #999999
    margin-left: 15px
    transition: all .3s ease-in-out
    &:hover
      color: #333
