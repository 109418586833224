@import "../../../assets/variables"

.indicators_container
  display: flex
  width: 90%
  margin-top: 2%
  margin-left: 5%
  div
    color: #FFF
    width: 90%
    padding: 1% 2%
    border-radius: 7px
    display: flex
    font-weight: 400
    box-shadow: 0px 5px 10px rgba(0,0,0,0.2)
    background-color: $primaryBlue
    &:first-child
      margin-right: 2%
      h2
        text-align: right
    h2
      font-weight: 400
      font-size: 36px
      margin: 0
      flex-grow: 1
    h5
      font-weight: 500
      margin: 0
      align-self: flex-end
      flex-grow: 3
    &:nth-child(2)
      background-color: $darkerBlue
      margin-left: 2%
      h5
        text-align: right
.chart_professionals_agendamentos
  width: calc(90% - 60px)
  border-radius: 12px
  margin-bottom: 30px
  padding: 10px 30px
  height: 500px
  box-shadow: 0px 5px 12px rgba(0,0,0,0.2)
  display: flex
  flex-direction: column
  margin-left: 5%
  & > form
    display: flex
    align-items: center
    margin: 15px
    div
      display: flex
      flex-direction: column
      margin-right: 50px
      width: 100%
      label
        display: block
        margin-right: 10px
      input
        margin-top: 5px
        border: 1px solid #888
        border-radius: 3px
        display: block
        padding: 5px 12px
  & > div:last-of-type
    flex: 1
.bottom_column
  display: flex
  margin-top: 2%
  margin-left: 5%
  margin-bottom: 2%
  .agendamentos_chart_container
    width: 55%
    border-radius: 10px
    margin-right: 2%
    padding: 1% 2%
    box-shadow: 0px 5px 12px rgba(0,0,0,0.2)
    h2
      margin: 2%
    #chart_agendamentos
      height: 80%
  .quickdetails_container
    width: 30%
    border-radius: 10px
    padding: 1% 2%
    box-shadow: 0px 5px 12px rgba(0,0,0,0.2)
    h3
      margin: 8.5% 2%
      font-weight: 400
      color: $darkGrey

@media(max-width: 641px)
  .indicators_container
    flex-wrap: wrap
    div
      width: 100%
      margin-bottom: 2%
      &:first-child
        margin-right: 0
        flex-direction: row-reverse
        h2
          text-align: left
        h5
          text-align: right
      &:nth-child(2)
        background-color: $darkerBlue
        margin-left: 0
        text-align: left
  .bottom_column
    flex-wrap: wrap
    .agendamentos_chart_container
      width: 90%
      height: 450px
    .quickdetails_container
      width: 90%
      margin-top: 5%
      margin-bottom: 3%
