@import "../../../assets/variables.sass"

.display-none
  display: none

.topbar_container
  box-shadow: 0 5px 6px rgba(0,0,0,0.1)
  .notifications
    position: relative
    margin-top: -18px
    margin-left: 2.2vw
    top: 10px
    color: #FFF
    background-color: #FF4444
    width: 20px
    padding: 3px
    height: 20px
    font-size: 13px
    font-weight: 500
    display: flex
    justify-content: center
    align-items: center
    border-radius: 500px
    @include putTransition()
    &.hidden
      transform: scale(0)
  & > div
    display: flex
  .titulo
    padding-left: 20px
    flex-grow: 1
    margin: auto
  h2
    color: $darkGrey
  h4
    color: $grey
  h2, h3, h4
    margin: 0
    font-weight: 400
  .log-off
    background-color: transparent
    box-shadow: none
    color: $grey
    font-size: 1.3em
    &:hover
      color: $darkGrey
  .img_container
    background-color: $primaryBlue
    width: 10%
    display: flex
    padding-right: 0px
    justify-content: center
    border-radius: 0 500px 500px 0
    img
      margin: auto
      width: 70%
      height: intrinsic
  .user_data, .profile_pic
    margin: auto 0
  .profile_pic
    border-radius: 500px
    width: 53px
    height: 53px
    cursor: pointer
    margin: 15px 0
    margin-right: 5px
  .user_data
    padding-right: 25px
    h3, h4
      text-align: right
  .salas_container
    display: none

//Para celulares
@media (max-width: 641px)
  .topbar_container
    position: sticky
    top: 0
    background-color: #FFF
    z-index: 1001
    margin-bottom: 5%
    .content
      display: flex
      padding: 10px 0
    .salas_container
      position: relative
      overflow: paged-x
      overflow-x: auto
      margin: 0
      width: 100%
      display: flex
      & > div
        width: 100px
        @include putTransition(.3s)
        border-bottom: 5px solid #FFF
        &.selected
          border-bottom: 5px solid $primaryBlue
      p
        padding: 4px 0 0 0
        text-align: center
        width: 80px
    .titulo
      display: none
    .user_data, .profile_pic
      display: none
    .img_container
      justify-content: center
      margin: auto
      flex: 1
      padding: 0
      height: 35px
      background-color: transparent
      img
        height: 35px
        object-fit: scale-down
    .log-off
      padding: 20px
      margin: auto
      i
        color: $primaryBlue
      img
        margin-right: -15px
        padding: 20% 5%
        width: 100%
