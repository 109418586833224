@import "../../variables"

.card_log
  box-shadow: 0 2px 6px rgba(0,0,0,0.2)
  padding: 10px 20px
  margin: 5px 0
  display: flex
  border-radius: 10px
  h3, h4
    color: $grey
    font-weight: 400
  h3
    font-size: 16px
  h1
    font-weight: 500
    font-size:  17px
    color: $darkGrey
  h2, h1, h3, h4
    margin: auto 0
  img
    border-radius: 500px
    width: 40px
    height: 40px
    object-fit: cover
  div:nth-child(2)
    flex-grow: 1
    margin-left: 2%
  div:last-child
    display: flex
    flex-direction: column
    justify-content: center
    text-align: right