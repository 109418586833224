@import "./src/assets/variables"

.profissionais_container
  display: flex
  width: 90%
  justify-content: center
  margin: 2%
  margin-left: 5%
  flex-wrap: wrap

.visualizacao
  width: 90%
  margin-left: 5%
  color: #888
  .change_list
    font-style: italic
    cursor: pointer
    @include putTransition()
    &:hover
      color: #333

.profissionais_list_container
  width: 90%
  margin: 2%
  margin-left: 5%
  table
    width: 100%


//para celular
@media (max-width: 655px)
  .profissionais_container
    flex-wrap: wrap
    justify-content: center
    .card_profissional
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)
      border-radius: 7px
      text-align: center
      width: 80%
      padding: 20px
      margin-bottom: 10px
      h2
       font-size: 12pt
      h3
        font-size: 10pt

   