@import "../../variables.sass"

.sala_container
  position: relative
  display: flex
  width: 90%
  padding-top: 2%
  margin-left: 5%
  .btn_detalhes
    width: 35%
    align-self: flex-end
    margin: auto 0
  div
    flex-grow: 1
    display: flex
    flex-direction: column
  h1, p
    margin: 0
  h1
    color: $darkGrey
    span
      font-size: 20px
      cursor: pointer
      color: $grey
      @include putTransition()
      &.fixa
        color: $primaryBlue
      &:hover(:not(.fixa))
        color: $darkGrey
      i
        transform: rotate(-45deg)
  p
    color: $grey
  .carregando
    font-style: italic

.aluguel_mensal
  color: $grey
  text-align: center
  font-weight: 400
  padding-bottom: 45px

@media (max-width: 641px)
  .sala_container
    .btn_detalhes
      width: 100%