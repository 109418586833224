@import "./src/assets/variables"

.info_container
  display: flex
  width: 90%
  margin-left: 5%
  .snack_container
    margin: auto 0
    display: flex
.container_salas
  margin-top: 30px
  background-color: #FFFFFF
  width: 90%
  margin-left: 5%
  margin-bottom: 2%
  border-radius: 7px
  box-shadow: 0 2px 10px rgba(0,0,0,0.2)

@media (max-width: 641px)
  .agendamentos_cliente
    .container_salas
      display: none
  .info_container
    flex-wrap: wrap
    div
      width: 100%
